import {required} from 'vuelidate/lib/validators'
import {mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                domain: '',
                api_key: '',
                price_ratio: '',
            },
        }
    },

    validations: {
        form: {
            title: {
                required
            },
            domain: {
                required
            },
            api_key: {
                required
            },
            price_ratio: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            site: 'catalog/site',
        }),
    },
    watch: {
        site(e) {
            // console.log(e)
            if (e) {
                this.form.title = e.title;
                this.form.domain = e.domain;
                this.form.api_key = e.api_key;
                this.form.price_ratio = e.price_ratio;
            }
        },
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        sendModel() {
            if (this.site) {
                const payload = {
                    id: this.site.id,
                    data: this.form
                }
                this.$emit('changeSite', payload)
            } else {
                this.$emit('sendModel', this.form)
            }
        },
        // ...mapActions({
        //     getProductCategories: 'catalog/getProductCategories',
        //     getPriceCategories: 'catalog/getPriceCategories',
        //     getDepartments: 'catalog/getDepartments',
        //     getMaterials: 'catalog/getMaterials',
        //     getModels: 'catalog/getModels',
        //     getManufacturers: 'catalog/getManufacturers',
        //     storeProductImage: 'catalog/storeProductImage'
        // }),
        ...mapMutations({
            changeSite: 'catalog/changeSite'
        }),
    },
    destroyed() {
        this.changeSite(null);
    }
}